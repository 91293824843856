import { getNoticeList } from "@/api/notice";

const noticeStore = {
  namespaced: true,
  state: {
    noticeList: [],
    totalCnt: 0,
    pageNo: 1,
  },
  getters: {
    getNoticeList(state) {
      return state.noticeList;
    },
    isOpen: (state) => (index) => {
      return state.noticeList[index]?.open || false;
    },
  },
  mutations: {
    SET_NOTICE_LIST(state, noticeList) {
      state.noticeList = noticeList;
    },
    ADD_NOTICE_LIST(state, noticeList) {
      state.noticeList.push(...noticeList);
    },
    CLEAR_NOTICE_LIST(state) {
      state.noticeList = [];
    },
    SET_TOTAL_CNT(state, totalCnt) {
      state.totalCnt = totalCnt;
    },
    SET_PAGE_NO(state, pageNo) {
      state.pageNo = pageNo;
    },
    SET_IS_OPEN(state, { index, isOpen }) {
      if (state.noticeList[index]) {
        state.noticeList[index].open = isOpen;
      }
    },
  },
  actions: {
    async GET_NOTICE_LIST({ commit }, params) {
      const { data } = await getNoticeList(params);
      if (params.pageNo == 1) {
        commit("SET_NOTICE_LIST", data.data.list);
      } else {
        commit("ADD_NOTICE_LIST", data.data.list);
      }
      commit("SET_TOTAL_CNT", data.data.totalCnt);
    },
    async SET_PAGE_NO({ commit }, pageNo) {
      commit("SET_PAGE_NO", pageNo);
    },
    TOGGLE_ACCORDION({ commit, state }, index) {
      const isOpen = !state.noticeList[index]?.open;
      commit("SET_IS_OPEN", { index, isOpen });
    },
  },
};

export default noticeStore;
