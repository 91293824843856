<template>
  <div>
    <div class="wrap" id="wrap">
      <div class="section-cont">
        <button
          type="button"
          class="btn-sec btn--small"
          data-roll="modal-popup"
          data-popid="modalPop01"
        >
          로그아웃 안내
        </button>
      </div>
    </div>
    <!-- //wrap -->

    <!-- [팝업]로그아웃 안내 -->
    <div class="modal-popup modal-popup--xsmall" id="modalPop01"  style="z-index:1000;">
      <div class="modal-popup__wrap">
        <div class="modal-popup__head">
          <h1 class="modal-popup__title">로그아웃 안내</h1>
        </div>
        <div class="modal-popup__body">
          <div class="notice-msg">
            <i class="icon-logout"><!-- 로그아웃 아이콘 --></i>
            <p class="notice-msg__text">
              <strong>로그아웃 되었습니다.</strong>
            </p>
          </div>

          <div class="btn-wrap">
            <button @click="moveUrl" class="btn-pri btn--large">로그인</button>
          </div>
        </div>
        <!-- //modal-popup__body -->
        <div class="modal-popup__close">
          <button type="button" @click="popupClose">
            <span class="blind">팝업닫기</span>
          </button>
        </div>
      </div>
    </div>
    <!-- //[팝업]로그아웃 안내 -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: {
    isLogoutGuideShow: Boolean,
  },
  data: () => ({}),
  computed: {
    ...mapGetters("userStore", ["getSessionTime"]),
  },

  mounted() {
    this.modalPopupOpen("modalPop01");
  },

  methods: {
    //레어어팝업 열기
    modalPopupOpen(popUpID) {
      let openPopup = document.getElementById(popUpID);
      openPopup.classList.add("modal-popup--show");
      openPopup.setAttribute("tabindex", "0");
      openPopup.focus();
      document.body.style.overflow = "hidden";
    },
    popupClose() {
      this.modalPopupClose("modalPop01");
      this.$emit("isLogoutGuideShow", false);
      this.$store.dispatch("userStore/LOGOUT_GUIDE_SHOW_FALSE");
    },
    //레이어팝업 닫기
    modalPopupClose(popUpID) {
      let closePopup = document.getElementById(popUpID);
      closePopup.classList.remove("modal-popup--show");
      closePopup.removeAttribute("tabindex");
      document.body.removeAttribute("style");
    },
    moveUrl() {
      this.popupClose();
      this.$store.dispatch("userStore/LOGOUT_GUIDE_SHOW_FALSE");
      if (this.$route.path !== "/login") {
        this.$router.push("/login");
      }
    },
  },
};
</script>