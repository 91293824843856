<template>
  <!-- footer -->
  <footer class="footer section fp-auto-height">
    <div class="footer__inner">
      <div class="fnb">
        <ul class="fnb__wrap">
          <li>
            <a href="#n" class="fnb__link" style="color: red" @click="openPopup"
              >개인정보처리방침</a
            >
          </li>
          <li><a href="/policy-details" class="fnb__link">이용약관</a></li>
          <li>
            <a href="/credit-usage-notice" class="fnb__link"
              >신용정보활용체제공시</a
            >
          </li>
          <li>
            <a href="/email-no-collection" class="fnb__link"
              >이메일무단수집거부</a
            >
          </li>
        </ul>
      </div>
      <div class="footer__row">
        <div class="footer__logo">
          <img
            src="@/assets/images/footer-logo.png"
            alt="NICE dun & bradstreet"
          />
        </div>

        <div class="footer__familySite">
          <select id="familySite" name="familySite" @change="redirectUrl()">
            <option value="" disabled selected>Family site</option>
            <option value="https://nicednb.com">나이스디앤비 홈페이지</option>
            <option value="https://global.nicednb.com">
              나이스디앤비 글로벌 홈페이지
            </option>
          </select>
        </div>
      </div>

      <address class="site-address">
        (04535) 서울특별시 중구 소공로 70, 포스트타워  16층 (주)NICE디앤비<br />
        통신판매업신고: 제2024-서울중구-1736호 사업자등록번호: 107-86-24874 TEL:
        02-2122-0233 FAX: 02-2122-2591
      </address>

      <p class="copyright">ⓒ 2023. NICE D&B Co.,Ltd. ALL RIGHTS RESERVED.</p>
    </div>
  </footer>
  <!-- //footer -->
</template>
<script>
export default {
  components: {},
  data: () => ({}),
  methods: {
    redirectUrl() {
      var selectElement = document.getElementById("familySite");
      var selectedValue = selectElement.value;
      if (selectedValue) {
        window.open(selectedValue, "_blank");
        selectElement.selectIndex = 0;
      }
    },
    openPopup() {
      window.open(
        "https://www.nicednb.com/cs/cs-center03-pp.do",
        "etc_popup",
        "left=50,top=50,width=680,height=650,scrollbars=1"
      );
    },
  },
};
</script>