<template>
  <div></div>
</template>

<script>
export default {
  name: "WinPopup",
  methods: {
    openWinPop(uri, width, height) {
      if (this.windowRef != null) {
        this.closeWinPop();
      }

      let left = screen.width ? (screen.width - width) / 2 : 0;
      let top = screen.height ? (screen.height - height) / 2 : 0;

      let attr = `top=${top}, left=${left}, width=${width}, height=${height}, resizable=no, status=no, menubar=no, toolbar=no, location=no`;
      this.windowRef = window.open(uri, "", attr);

      if (this.windowRef != null) {
        this.windowRef.addEventListener("beforeunload", this.evtClose);
      }
      window.addEventListener("message", this.recvEvtFromChild, false);
    },

    closeWinPop() {
      if (this.windowRef) {
        this.windowRef.close();
        this.windowRef = null;
      }
    },

    evtClose() {
      if (this.windowRef) {
        this.windowRef.close();
        this.windowRef = null;
        this.$emit("onClose");
      }
    },
    // 5. 팝업창으로 부터 수신된 이벤트
    recvEvtFromChild(evt) {
      if (evt.data == null) {
        return;
      }

      this.$emit("onRecvEvtFromWinPop", evt.data);
    },
  },
  data() {
    return {
      windowRef: null,
    };
  },
};
</script>