import { instanceWithAuth, blobResponseInstanceWithAuth } from "@/api/index";

function postBuyerAppList(data) {//Duns 검색
    return instanceWithAuth.post("/report/buyer-app-list", data);
}

function postBuyerCmpNmList(data) {//기업명 검색
    return instanceWithAuth.post("/report/buyer-cmp-list", data);
}

function getAttachment(data) {
    return instanceWithAuth.get("/report/attach-insert", { params: data });
}

function postReportAppList(data) {
    return instanceWithAuth.post("/report/app-list", data);
}

function validateAndGetFile(param) {
    return blobResponseInstanceWithAuth.get("/report/valid-file", { params: param });
}

function getValidReportApplyCnt(param) {
    return instanceWithAuth.get("/report/valid-apply-cnt", { params: param });
}

export { postBuyerAppList, getAttachment, postReportAppList, postBuyerCmpNmList, validateAndGetFile, getValidReportApplyCnt };
