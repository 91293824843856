<template >
  <Modal ref="baseModal">
    <div class="modal-alert modal-alert--show" id="modalConfirm" style="tabindex: 0;">
      <div class="modal-alert__wrap">
        <div class="modal-alert__head">
          <h1 class="modal-alert__title">{{title}}</h1>
        </div>
        <div class="modal-alert__body">
          <p class="modal-alert__msg" v-html="content"></p>
        </div>
        <div class="modal-alert__btnArea">
          <button
            id="modal-confirm-btn-confirm"
            type="button"
            class="btn-alert-action"
            @click="confirm"
          >{{confirmButtonText}}</button>
          <button
            id="modal-confirm-btn-cancel"
            type="button"
            class="btn-alert-cancel"
            @click="cancel"
          >{{cancelButtonText}}</button>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/common/Modal.vue";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      title: "확인",
      content: "확인해 주세요.",
      confirmButtonText: "확인",
      cancelButtonText: "취소",
      resolvePromise: undefined,
    };
  },
  methods: {
    show(content) {
      this.setContent(content);
      this.$refs.baseModal.open();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
      });
    },
    confirm() {
      this.$refs.baseModal.close();
      this.resolvePromise(true);
    },
    cancel() {
      this.$refs.baseModal.close();
      this.resolvePromise(false);
    },
    setContent(content) {
      this.title = content.title ? content.title : this.title;
      this.content = content.content ? content.content : this.content;
      this.confirmButtonText = content.confirmButtonText
        ? content.confirmButtonText
        : this.confirmButtonText;
      this.cancelButtonText = content.cancelButtonText
        ? content.cancelButtonText
        : this.cancelButtonText;
    },
  },
  mounted() {},
};
</script>