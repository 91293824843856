import store from "@/store/index";
// import { refreshTokenUser } from "@/api/user";
import Swal from "sweetalert2";
import router from "@/router";
import VueCookies from "vue-cookies";

// let isTokenRefreshing = false;
let refreshSubscribers = [];

//403 에러가 발생된 request는 onTokenRefreshed 함수를 통해
//재전송 하는데 callback에는 여러개의 함수가 쌓일 수 있으므로 map을 통해 실행하고 인자로 새로 받은 accessToken을 넣어주면서 실행한다.
const onTokenRefreshed = (accessToken) => {
  refreshSubscribers.map((callback) => callback(accessToken));
};

//콜백함수를 받아서 refreshSubscribers라는 배열에 받은 콜백 함수를 push해주는 역할을 한다.
const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

export function setInterceptors(instance) {
  instance.interceptors.request.use(
    function (config) {
      const accessToken = store.getters["userStore/getAccessToken"];
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const {
        config,
        response: { status },
      } = err;
      const originalRequest = config;
      if (status === 401 && err.response.data.code === "A003") {
        VueCookies.remove("userId");
        VueCookies.remove("userGrade");
        VueCookies.remove("accessToken");
        Swal.fire({
          position: "center",
          html: ` <div class="modal-alert__wrap">
                    <div class="modal-alert__head">
                      <h1 class="modal-alert__title">알림</h1>
                    </div>
                    <div class="modal-alert__body">
                      <p class="modal-alert__msg">장시간 사용하지 않아 로그아웃 되었습니다.</p>
                    </div>
                  </div>`,
          showConfirmButton: false,
          timer: 2500,
        });
        router.push("/login");
      }
      else if (status === 401 && err.response.data.code === "A004") {
        VueCookies.remove("userId");
        VueCookies.remove("userGrade");
        VueCookies.remove("accessToken");
        if (router.path !== "/login") {
          router.push("/login");
        }
      }
      return Promise.reject(err);
      //새로운 요청 또한 실패했다면 Promise.reject(err)를 통해 error를 반환한다.
    }
  );

  return instance;
}
