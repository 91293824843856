import { searchAddress } from "@/api/user";

const addressStore = {
  namespaced: true,
  state: {
    addressList: [],
  },
  getters: {
    getAddressList(state) {
      return state.addressList;
    },
  },
  mutations: {
    SET_ADDRESS_LIST(state, addressList) {
      state.addressList = addressList;
    },
    CLEAR_ADDRESS_LIST(state) {
      state.addressList = [];
    },
  },
  actions: {
    async GET_ADDRESS_LIST({ commit }, params) {
      const { data } = await searchAddress(params);
      commit("SET_ADDRESS_LIST", data.data);
    },
  },
};

export default addressStore;
