import { getContactList } from "@/api/contact";
import { noticeBizCmpNm } from "@/api/customer";

const contactStore = {
  namespaced: true,
  state: {
    contactList: [],
    totalCnt: 0,
    pageNo: 1,
    noticeBizCmp: {},
  },
  getters: {
    getContactList(state) {
      return state.contactList;
    },
    isOpen: (state) => (index) => {
      return state.contactList[index]?.open || false;
    },
    getNoticeBizCmp(state) {
      return state.noticeBizCmp;
    },
  },
  mutations: {
    SET_CONTACT_LIST(state, contactList) {
      state.contactList = contactList;
    },
    ADD_CONTACT_LIST(state, contactList) {
      state.contactList.push(...contactList);
    },
    CLEAR_CONTACT_LIST(state) {
      state.contactList = [];
    },
    SET_TOTAL_CNT(state, totalCnt) {
      state.totalCnt = totalCnt;
    },
    SET_PAGE_NO(state, pageNo) {
      state.pageNo = pageNo;
    },
    SET_IS_OPEN(state, { index, isOpen }) {
      if (state.contactList[index]) {
        state.contactList[index].open = isOpen;
      }
    },
    SET_NOTICE_BIZ_CMP(state, noticeBizCmp) {
      state.noticeBizCmp = noticeBizCmp;
    },
  },
  actions: {
    async GET_CONTACT_LIST({ commit }, params) {
      const { data } = await getContactList(params);
      if (params.pageNo == 1) {
        commit("SET_CONTACT_LIST", data.data.list);
      } else {
        commit("ADD_CONTACT_LIST", data.data.list);
      }
      commit("SET_TOTAL_CNT", data.data.totalCnt);
    },
    TOGGLE_ACCORDION({ commit, state }, index) {
      const isOpen = !state.contactList[index]?.open;
      commit("SET_IS_OPEN", { index, isOpen });
    },
    async GET_NOTICE_BIZ_CMP({ commit }) {
      const { data } = await noticeBizCmpNm();
      commit("SET_NOTICE_BIZ_CMP", data.data);
    },
  },
};

export default contactStore;
