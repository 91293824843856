import { instanceWithAuth } from "@/api/index";

function getCashProduct() {
  return instanceWithAuth.get("/cash/product-list");
}

function getChargeNo() {
  return instanceWithAuth.get("/cash/get-chrg-no");
}

function nicePay(payData) {
  return instanceWithAuth.get("/nicepay/encrypt", { params: payData });
}

function getCashUsageList(param) {
  return instanceWithAuth.get("/cash/cash-usage-list", { params: param });
}

function getCashUserBalance() {
  return instanceWithAuth.get("/cash/balance");
}

export { getCashProduct, nicePay, getChargeNo, getCashUsageList, getCashUserBalance };
