import { getBuyerDetail, getBuyerFavor } from "@/api/buyer";
import { getBuyerSearch, buyerCategorySearch } from "@/api/search";
const buyerStore = {
    namespaced: true,
    state: {
        buyers: [],
        buyersDetail: [],
        //바이어조회 변수        
        buyerLocalItems: [],
        visibleBuyerCount: 10,
        buyerOffset: 0,
        buyerTotalCount: 0,
        //필터링 조건
        fttotalBuyerYn: 'Y', // filter => ft  
        ftkrTradeYn: 'N',
        ftfavorYn: 'N',
        ftnatCd: '',
        ftkeyword: '',
        ftCategoryNo: "",
        ftSicCd: null,
        ftGubunCd: '001', // 입력값 유형 구분자
        ftNeedCount: false, // 총건수 필요 불필요 구분자
    },
    getters: {
        getBuyerDetail(state) {
            return state.buyerDetail;
        },
    },
    mutations: {
        SET_FTYN_TOTAL(state, param) {
            state.fttotalBuyerYn = param;
        },
        SET_FTYN_TR(state, param) {
            state.ftkrTradeYn = param;
        },
        SET_FTFYN_FAVOR(state, param) {
            state.ftfavorYn = param;
        },
        SET_FTNATION(state, param) {
            state.ftnatCd = param;
        },
        SET_FKEY(state, param) {
            state.ftkeyword = param;
        },
        SET_FTCATEGORY_NO(state, param) {
            state.ftCategoryNo = param;
        },
        SET_FTSIC_CD(state, param) {
            state.ftSicCd = param;
        },
        SET_GUBUNCD(state, param) {
            state.ftGubunCd = param;
        },
        SET_NEEDCOUNT(state, param) {
            state.ftNeedCount = param;
        },
        SET_BUYER_LIST(state, buyers) {
            state.buyers = buyers;
        },
        SET_BUYER_DETAILS(state, buyersDetail) {
            state.buyersDetail = buyersDetail;
            // 구매한 바이어에 대해 다시 차감 팝압창이 안 뜨도록 수정
            state.buyerLocalItems.forEach(element => {
                if (buyersDetail.dunsNo != null && buyersDetail.dunsNo == element.dunsNo) {
                    element.chargeBuyerDetail = true;
                }
            });
        },
        SET_BUYER_TOTAL_COUNT(state, buyerTotalCount) {
            state.buyerTotalCount = buyerTotalCount > 500 ? 500 : buyerTotalCount;
        },
        SET_BUYEROFFESET(state, param) {
            state.buyerOffset = param;
        },
        DELETE_BUYERLOCAL_ITEM(state, param) {
            state.buyerLocalItems = param;
        },
        SET_BUYERVISIBLE_COUNT(state, param) {
            state.visibleBuyerCount = param;
        },
        SET_PUSH_LOCAL_BUYER(state, param) {
            param.forEach(element => {
                state.buyerLocalItems.push(element);
            });
        },
        SET_DETAIL_FAVOR(state, param) {
            //state.buyersDetail.status = param.
            // 바이어 상세에서 즐겨찾기를 할경우,
            // 뒤로가기를 눌렀을 경우, 바이어 조회 화면에서도
            // 즐겨찾기가 되어야한다. 싱크를 맞추기 위해 state의 리스트에 update를 해줌 
            state.buyerLocalItems.forEach(element => {
                if (element.dunsNo == param.dunsNo) {
                    element.favorYn = param.status ? 'Y' : 'N';
                }
            });
        }
    },
    actions: {
        // 2024-02-29 
        async GET_BUYER_LIST({ commit, state }) {
            var params = {
                query: state.ftkeyword,
                natCd: state.ftnatCd,
                krTradeYn: state.ftkrTradeYn,
                pageNo: state.buyerOffset - 1,
            };
            if (state.ftSicCd != null) {
                params.sicCd = state.ftSicCd.cd;
            }

            const { data } = await getBuyerSearch(params);
            commit("SET_PUSH_LOCAL_BUYER", data.data.buyerList);
            if (data.data.buyerListCount != -1) // 더보기 버튼을 누르면 -1을 리턴, 즉 건수를 업데이트 하면 안된다.
                commit("SET_BUYER_TOTAL_COUNT", data.data.buyerListCount);
        },
        async GET_BUYER_CATEGORY_LIST({ commit, state }) {
            var params = {
                query: state.ftCategoryNo,
                natCd: state.ftnatCd,
                krTradeYn: state.ftkrTradeYn,
                pageNo: state.buyerOffset - 1,
            };
            if (state.ftSicCd != null) {
                params.sicCd = state.ftSicCd.cd;
            }
            
            const { data } = await buyerCategorySearch(params);
            commit("SET_PUSH_LOCAL_BUYER", data.data.buyerList);
            if (data.data.buyerListCount != -1) // 더보기 버튼을 누르면 -1을 리턴, 즉 건수를 업데이트 하면 안된다.
                commit("SET_BUYER_TOTAL_COUNT", data.data.buyerListCount);
        },
        async GET_BUYER_DETAIL({ commit }, params) {
            const { data } = await getBuyerDetail(params);
            commit("SET_BUYER_DETAILS", data.data);
        },
        async UPDATE_FAVOR({ commit }, params) {
            const { data } = await getBuyerFavor(params);
            var retData = {
                dunsNo: params.dunsNo,
                status: data.data,
            }
            commit("SET_DETAIL_FAVOR", retData);
        },
        DELETE_BUYERLOCAL_ITEM({ commit }, param) {
            commit("DELETE_BUYERLOCAL_ITEM", param);
            commit("SET_BUYEROFFESET", 0);
            commit("SET_BUYERVISIBLE_COUNT", 10);
        },
        SET_ADD_OFFSET({ commit, state }) {
            commit("SET_BUYEROFFESET", state.buyerOffset + 1);
        },
        SET_ADD_VISIBLE_COUNT({ commit, state }) {
            commit("SET_BUYERVISIBLE_COUNT", state.visibleBuyerCount + 10);
        },
        SET_FILTER({ commit }, param) {
            commit("SET_FTYN_TOTAL", param.totalBuyerYn);
            commit("SET_FTYN_TR", param.krTradeYn);
            commit("SET_FTFYN_FAVOR", param.favorYn);
            commit("SET_FTNATION", param.natCd);
            commit("SET_FKEY", param.keyword);
            commit("SET_FTCATEGORY_NO", param.categoryNo);
            commit("SET_FTSIC_CD", param.sicCd);
            commit("SET_GUBUNCD", param.gubunCd); // 입력구분 : 001 기업명, 002 던스,003 관심품목            
        },
        SET_NEEDCOUNT({ commit }, param) {
            commit("SET_NEEDCOUNT", param);
        }
    }
}

export default buyerStore;