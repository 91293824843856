import { getCategory, getCountryCodes, getFaqCodes, getNoticeCodes } from "@/api/common";
import Swal from "sweetalert2";

const commonStore = {
    namespaced: true,
    state: {
        categories: [],
        countryCodes: [],
        faqCodes: [],
        noticeCodes: [],
    },
    getters: {
        categories(state) {
            return state.categories;
        },
        countryCodes(state) {
            return state.countryCodes;
        },
        faqCodes(state) {
            return state.faqCodes;
        },
        noticeCodes(state) {
            return state.noticeCodes;
        },
    },
    mutations: {
        SET_CATEGORIES(state, categoryList) {
            state.categories.push(...categoryList);
        },
        SET_COUNTRY_CODES(state, countryCodeList) {
            state.countryCodes.push(...countryCodeList);
        },
        SET_FAQ_CODES(state, faqCodeList) {
            state.faqCodes.push(...faqCodeList);
        },
        SET_NOTICE_CODES(state, noticeCodes) {
            state.noticeCodes.push(...noticeCodes);
        },
    },
    actions: {
        async GET_CATEGORIES({ state, commit }) {
            if (state.categories.length == 0) {
                try {
                    const { data } = await getCategory();
                    commit("SET_CATEGORIES", data.data);
                } catch (err) {
                    let content = "데이터를 불러오는 중 오류가 발생하였습니다.";
                    if (err.response != undefined) {
                        content = err.response.data.message;
                    }
                    Swal.fire({
                        position: "center",
                        html: ` <div class="modal-alert__wrap">
                                    <div class="modal-alert__head">
                                    <h1 class="modal-alert__title">알림</h1>
                                    </div>
                                    <div class="modal-alert__body">
                                    <p class="modal-alert__msg">${content}</p>
                                    </div>
                                </div>`,
                        showConfirmButton: false,
                        timer: 2500,
                    });

                }
            }
        },
        async GET_COUNTRY_CODES({ state, commit }) {
            if (state.countryCodes.length == 0) {
                try {
                    const { data } = await getCountryCodes();
                    commit("SET_COUNTRY_CODES", data.data);
                } catch (err) {
                    let content = "데이터를 불러오는 중 오류가 발생하였습니다.";
                    if (err.response != undefined) {
                        content = err.response.data.message;
                    }
                    Swal.fire({
                        position: "center",
                        html: ` <div class="modal-alert__wrap">
                                    <div class="modal-alert__head">
                                    <h1 class="modal-alert__title">알림</h1>
                                    </div>
                                    <div class="modal-alert__body">
                                    <p class="modal-alert__msg">${content}</p>
                                    </div>
                                </div>`,
                        showConfirmButton: false,
                        timer: 2500,
                    });
                }
            }
        },
        async GET_FAQ_CODES({ state, commit }) {
            if (state.faqCodes.length == 0) {
                try {
                    const { data } = await getFaqCodes();
                    commit("SET_FAQ_CODES", data.data);
                } catch (err) {
                    let content = "데이터를 불러오는 중 오류가 발생하였습니다.";
                    if (err.response != undefined) {
                        content = err.response.data.message;
                    }
                    Swal.fire({
                        position: "center",
                        html: ` <div class="modal-alert__wrap">
                                    <div class="modal-alert__head">
                                    <h1 class="modal-alert__title">알림</h1>
                                    </div>
                                    <div class="modal-alert__body">
                                    <p class="modal-alert__msg">${content}</p>
                                    </div>
                                </div>`,
                        showConfirmButton: false,
                        timer: 2500,
                    });
                }
            }
        },
        async GET_NOTICE_CODES({ state, commit }) {
            if (state.noticeCodes.length == 0) {
                try {
                    const { data } = await getNoticeCodes();
                    commit("SET_NOTICE_CODES", data.data);
                } catch (err) {
                    let content = "데이터를 불러오는 중 오류가 발생하였습니다.";
                    if (err.response != undefined) {
                        content = err.response.data.message;
                    }
                    Swal.fire({
                        position: "center",
                        html: ` <div class="modal-alert__wrap">
                                    <div class="modal-alert__head">
                                    <h1 class="modal-alert__title">알림</h1>
                                    </div>
                                    <div class="modal-alert__body">
                                    <p class="modal-alert__msg">${content}</p>
                                    </div>
                                </div>`,
                        showConfirmButton: false,
                        timer: 2500,
                    });
                }
            }
        },
    },
}

export default commonStore;